import {authFetch} from "../helpers/auth-helpers";

export class PdfBackend {
    constructor() {
    }

    public askThatPaper(url: string): Promise<any> {
        return authFetch(`https://api.ask-that-paper.com/pdf-backend/ask-that-paper/summary`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"url": url})
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        return {
                            message: null,
                            conversationId: null,
                            index: null,
                            error: result.error
                        }
                    }
                    return this.fetchMessage(result);
                },
                (error) => {
                    return {
                        message: null,
                        conversationId: null,
                        index: null,
                        error: 'unknown error, unable to get paper summary'
                    }
                }
            );
    }

    public askThatPaperAQuestion(conversationId: string, question: string): Promise<any> {
        return authFetch(`https://api.ask-that-paper.com/pdf-backend/ask-that-paper/question`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"conversationId": conversationId, "question": question})
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        return {
                            message: null,
                            conversationId: null,
                            index: null,
                            error: result.error
                        }
                    }
                    return this.fetchMessage(result);
                },
                (error) => {
                    return {
                        message: null,
                        conversationId: null,
                        index: null,
                        error: 'unknown error, unable to get answer'
                    }
                }
            );
    }

    private async fetchMessage(result: any): Promise<any> {
        let summary = undefined;
        for (let i = 0; i < 50; i++) {
            summary = await authFetch(`https://api.ask-that-paper.com/pdf-backend/ask-that-paper/conversation`, {
                credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "conversationId": result.conversationId,
                    "index": result.index ?? 3,
                })
            }).then(res => res.json());
            if (summary && summary.message) {
                break;
            } else {
                await this.sleep(1000);
            }
        }

        if (summary) {
            return summary;
        } else {
            return {
                message: null,
                conversationId: null,
                index: null,
                error: 'unknown error, unable to get paper summary'
            }
        }
    }

    private sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}