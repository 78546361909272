import {plainFetch} from "../helpers/auth-helpers";

export class SignInSource {
    constructor() {
    }

    refreshCredentials(): Promise<any> {
        return plainFetch("https://api.ask-that-paper.com/auth/refresh-credentials", {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        credentials: result.credentials,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        credentials: null,
                        error: 'unknown error, unable to login'
                    }
                }
            );
    }

    signIn(email: string, password: string): Promise<any> {
        return plainFetch("https://api.ask-that-paper.com/auth/sign-in", {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"username": email, "password": password})
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        credentials: result.credentials,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        credentials: null,
                        error: 'unknown error, unable to login'
                    }
                }
            );
    }

    signInOauth(source: string): Promise<any> {
        return Promise.resolve({});
    }
}