import React, {useEffect, useRef, useState} from 'react';
import {SignInSource} from "../services/SignInSource";
import logo from '../ask-that-paper.png';
import {PdfBackend} from "../services/PdfBackend";
import {Message} from "../models/Message";
import {
    Box,
    Button,
    Center,
    Image,
    Input,
    Link,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Spacer,
    Flex,
    InputGroup,
    StackDivider,
    Alert,
    AlertIcon,
    Card,
    Heading,
    Kbd
} from "@chakra-ui/react";
import {AutoResizeTextarea} from "../components/AutoResizeTextarea";

interface MainPageProps {
    signInSource: SignInSource;
    pdfBackend: PdfBackend;
}

function MainPage(props: MainPageProps) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const divRef = useRef(null);

    const [url, setUrl] = useState("");
    const [signedIn, setSignedIn] = useState(false);
    const [asking, setAsking] = useState(false);
    const [askingQuestion, setAskingQuestion] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [credentials, setCredentials] = useState("");
    const [modalError, setModalError] = useState("");
    const [summaryError, setSummaryError] = useState("");
    const [summaryMessage, setSummaryMessage] = useState("");
    const [messageIndex, setMessageIndex] = useState("");
    const [question, setQuestion] = useState("");
    const [conversationId, setConversationId] = useState("");
    const [controlPressed, setControlPressed] = useState(false);
    const [enterPressed, setEnterPressed] = useState(false);
    const [messages, setMessages] = useState([
        // {
        //     text: "What is the meaning of life?",
        //     q: true
        // },
        // {
        //     text: "What is the meaning of life, yes",
        //     q: false
        // }
    ] as Message[]);

    useEffect(() => {
        props.signInSource.refreshCredentials().then(response => {
            if (response.credentials && !response.error) {
                setCredentials(response.credentials);
                setSignedIn(true);
            } else {
                setCredentials(response.credentials);
                setSignedIn(false);
            }
        });
    }, []);

    const handleEmailSignIn = (event: any) => {
        setSigningIn(true);
        event.preventDefault();
        setModalError("");
        props.signInSource.signIn(email, password).then(response => {
            if (response.credentials && !response.error) {
                setCredentials(response.credentials);
                setSignedIn(true);
                onClose();
            } else {
                setSignedIn(false);
                setCredentials(response.credentials);
                setModalError(response.error);
            }
            setSigningIn(false);
        });
    }

    const handleSignIn = (event: any, source: string) => {
        setSigningIn(true);
        event.preventDefault();
        setModalError("");
        props.signInSource.signInOauth(source).then(response => {
            if (response.credentials && !response.error) {
                setCredentials(response.credentials);
                setSignedIn(true);
                onClose();
            } else {
                setSignedIn(false);
                setCredentials(response.credentials);
                setModalError(response.error);
            }
            setSigningIn(false);
        });
    }

    const handleSubmit = (event: any) => {
        setAsking(true);
        setSummaryError("");
        event.preventDefault();

        if (signedIn) {
            props.pdfBackend.askThatPaper(url).then(response => {
                setMessages([]);
                setAsking(false);
                if (response.message) {
                    console.log(response);
                    setSummaryMessage(response.message);
                    setConversationId(response.conversationId);
                    setMessageIndex(response.index);
                } else {
                    setSummaryError(response.error);
                }
            });
        } else {
            setAsking(false);
            onOpen();
        }
    }

    const handleSendQuestion = (event: any) => {
        setAskingQuestion(true);
        event.preventDefault();

        messages.push({
            text: question,
            q: true
        });
        messages.push({
            text: "...",
            q: false
        });
        setMessages(messages);

        setTimeout(() => {
            if (divRef.current) {
                (divRef.current as Element).scrollIntoView({behavior: 'smooth'})
            }
        }, 0);

        if (signedIn) {
            props.pdfBackend.askThatPaperAQuestion(conversationId, question).then(response => {
                if (response.message) {
                    messages.pop();
                    messages.push({
                        text: response.message,
                        q: false
                    });
                    setMessages(messages);

                    setQuestion("");
                    setMessageIndex(response.index);
                } else {
                    messages.pop();
                    messages.push({
                        text: response.error,
                        q: false
                    });

                    setQuestion("");
                }
                setAskingQuestion(false);
            });
        } else {
            onOpen();
            setAskingQuestion(false);
        }
    }

    const handleEmailChange = (event: any) => {
        event.preventDefault();
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: any) => {
        event.preventDefault();
        setPassword(event.target.value);
    }

    const handleUrlChange = (event: any) => {
        event.preventDefault();
        setUrl(event.target.value);
    }

    const handleQuestionChange = (event: any) => {
        event.preventDefault();
        setQuestion(event.target.value);
    }


    const handleKey = (event: any, pressed: boolean) => {
        if (controlPressed && enterPressed) {
            handleSendQuestion(event);
            setEnterPressed(false);
            setControlPressed(false);
        }
        if (event.key === "Control") {
            setControlPressed(pressed);
        }
        if (event.key === "Enter") {
            setEnterPressed(pressed);
        }
    }

    const conversation = messages.map((message: any, idx: number) => {
        return (
            <>
                {message.q &&
                    <Flex minWidth="40%" alignItems="center" gap="2">
                        <Box></Box>
                        <Spacer/>
                        <Card textAlign="right" key={"msg-container" + idx} p={5} minWidth="200">
                            <Heading size="xs" textTransform="uppercase">
                                Question
                            </Heading>
                            <Text pt="2" fontSize="sm" whiteSpace="pre-line">
                                {message.text}
                            </Text>
                        </Card>
                    </Flex>
                }
                {!message.q &&
                    <Flex minWidth="40%" alignItems="center" gap="2">
                        <Card variant="elevated" textAlign="left" key={"msg-container" + idx} p={5} minWidth="200">
                            <Heading size="xs" textTransform="uppercase">
                                Answer
                            </Heading>
                            <Text pt="2" fontSize="sm" whiteSpace="pre-line">
                                {message.text}
                            </Text>
                        </Card>
                        <Spacer/>
                        <Box></Box>
                    </Flex>
                }
            </>
        );
    });

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Sign in</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <VStack divider={<StackDivider borderColor='gray.200'/>}>
                            {modalError &&
                                <Box w="99%">
                                    <Alert status="warning">
                                        <AlertIcon/>
                                        {modalError}
                                    </Alert>
                                </Box>
                            }
                            <Box m={5}>
                                <InputGroup className="emailSignIn">
                                    <VStack>
                                        <Input type="text" placeholder="Email" value={email}
                                               onChange={handleEmailChange}/>
                                        <Input type="password" placeholder="Password" value={password}
                                               onChange={handlePasswordChange}/>
                                        <Button className="signIn" w={200} isLoading={signingIn}
                                                loadingText="Signing-in"
                                                onClick={handleEmailSignIn}>Sign-in</Button>
                                    </VStack>
                                </InputGroup>
                            </Box>
                            <Box m={5}>
                                <VStack>
                                    <Box>
                                        <Button className="signInWith" w={200}
                                                onClick={(e) => handleSignIn(e, 'google')}>
                                            Sign-in with Google
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button className="signInWith" w={200}
                                                onClick={(e) => handleSignIn(e, 'apple')}>
                                            Sign-in with Apple
                                        </Button>
                                    </Box>
                                </VStack>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex flexDirection="column" minHeight="100vh">
                <Box>
                    <VStack align="stretch">
                        <Box textAlign="right" m={5}>
                            {!credentials && <Box className="signUpSignIn">
                                <Link onClick={onOpen}>Sign in</Link>
                            </Box>}
                            {credentials && <Box className="signUpSignIn">
                                <Link onClick={onOpen}>Sign out {credentials}</Link>
                            </Box>}
                        </Box>
                        <Box>
                            <Center>
                                <Image objectFit="contain" boxSize="100" src={logo} alt="logo"/>
                            </Center>
                        </Box>
                        <Box>
                            <Center m={5}>
                                <Input w="40%" type="text" placeholder="https://link-to-the-paper.pdf"
                                       onChange={handleUrlChange} value={url}/>
                            </Center>
                            {summaryError &&
                                <Center m={5}>
                                    <Box w="40%">
                                        <Alert status="warning">
                                            <AlertIcon/>
                                            {summaryError}
                                        </Alert>
                                    </Box>
                                </Center>
                            }
                            <Center m={5}>
                                <Button type="submit" onClick={handleSubmit}
                                        isLoading={asking} loadingText="Asking">Ask that paper</Button>
                            </Center>
                        </Box>
                    </VStack>
                </Box>
                {summaryMessage &&
                    <VStack divider={<StackDivider borderColor='gray.200'/>}>
                        <Box>
                            <Center>
                                <Text fontSize="s" whiteSpace="pre-line">{summaryMessage}</Text>
                            </Center>
                        </Box>
                        {conversation.length &&
                            <VStack minWidth="100%">
                                {conversation}
                            </VStack>
                        }
                        <Box minWidth="99%" mt={3}>
                            <VStack minWidth="99%">
                                <Box minWidth="99%">
                                    <Center>
                                        <AutoResizeTextarea placeholder='Ask a question...' w="30%" fontSize="s"
                                                            variant={'filled'} value={question}
                                                            onKeyDown={(s) => handleKey(s, true)}
                                                            onKeyUp={(s) => handleKey(s, false)}
                                                            onChange={(s) => handleQuestionChange(s)}
                                        />
                                        <Button fontSize="s" ml={5} type="submit" isLoading={askingQuestion}
                                                loadingText="Asking"
                                                onClick={handleSendQuestion}>Ask</Button>
                                    </Center>
                                </Box>
                                <Box fontSize="xs">
                                    <Kbd>Ctrl</Kbd> + <Kbd>Enter</Kbd> to ask
                                </Box>
                            </VStack>
                        </Box>
                    </VStack>
                }
                <Spacer/>
                <Box m={5} mt={10} ref={divRef}>
                    <Center>
                        <Text fontSize="xs">AskThatPaper is a service that allows you to ask for papers.</Text>
                    </Center>
                </Box>
            </Flex>
        </>
    );
}

export default MainPage;
