import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainPage from './pages/MainPage';
import reportWebVitals from './reportWebVitals';
import {SignInSource} from "./services/SignInSource";
import {MockSignInSource} from "./services/MockSignInSource";
import {PdfBackend} from "./services/PdfBackend";
import {MockPdfBackend} from "./services/MockPdfBackend";
import {
    ChakraProvider, ColorModeScript,
    Container,
    extendTheme, ThemeConfig
} from "@chakra-ui/react";

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
}

const theme = extendTheme({
    config,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const signInSource = (window.location.host === 'localhost:3000') ? new MockSignInSource() : new SignInSource();
const pdfBackend = (window.location.host === 'localhost:3000') ? new MockPdfBackend() : new PdfBackend();

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Container minHeight="100vh" minWidth="99vw">
                <MainPage signInSource={signInSource} pdfBackend={pdfBackend}/>
            </Container>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
